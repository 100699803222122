import React from "react";
import { Container } from "reactstrap";

export interface TopStripPromotionProps {
  display: boolean;
  innerRef: any;
  sticky: boolean;
}

export const TopStripPromotion: React.FunctionComponent<TopStripPromotionProps> = (props: TopStripPromotionProps) => {
  return (
    <>
      <div
        ref={props.innerRef}
        className={`top-strip text-center ${props.display === true ? "open" : "closed"} ${
          props.sticky === true ? "" : "fixed"
        }`}
      >
        <Container>
          <div className="promotion d-none d-md-inline">
            <a
              href="https://psychedelicstoday.teachable.com/a/aff_8cz1tjpk/external?affcode=80460_ria6swdh"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fw400">12-Month Training Program - </span>{" "}
              <span className="underline fw600">Starts in September! </span>
            </a>
          </div>
          <div className="promotion d-inline d-md-none">
            <a
              href="https://psychedelicstoday.teachable.com/a/aff_8cz1tjpk/external?affcode=80460_ria6swdh"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fw400">12-month Training Program - </span>{" "}
              <span className="underline fw600">Starts in September!</span>
            </a>
          </div>
        </Container>
      </div>
    </>
  );
};
